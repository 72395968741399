import React from 'react'
import { Link } from 'gatsby'

import { DefaultPageProps } from '../../types'
import { eventsFields } from '../../../config/constants'
import { useAsync, useAsyncEffect } from '../../hooks'
import { fetchCamps } from '../../api'
import { classes } from '../../utils'
import Layout from '../../components/layout'
import Throbber from '../../components/throbber'
import { FormCampsSignup } from '../../components/forms'
import Table from '../../components/table'

import { loading, disabled } from './index.module.sass'
import { CampData } from '../../../functions/camps/camps'

const Tabory = ({ location }: DefaultPageProps) => {
  const {
    value: rows,
    status,
    error,
    execute,
  } = useAsync<CampData>(fetchCamps, false)

  useAsyncEffect(execute)

  return (
    <Layout location={location} customClass="enrollment">
      <header>
        <h1>Přihlašování na tábory</h1>
      </header>
      <section className="page-content">
        <div>
          <h2>Aktuálně vypsané tábory</h2>
          {status === 'pending' && (
            <div className={loading}>
              <p>Přehled vypsaných táborů se ověřuje...</p>
              <Throbber />
            </div>
          )}
          {status === 'success' ? (
            <Table>
              <thead>
                <tr>
                  <th>{eventsFields.name}</th>
                  <th>{eventsFields.eventDate}</th>
                  <th>{eventsFields.closeDate}</th>
                  <th>Zbývající volná místa</th>
                </tr>
              </thead>
              <tbody>
                {rows.length ? (
                  rows.map((row) => (
                    <tr
                      key={row.id}
                      className={classes(!row.active && disabled)}
                    >
                      <td>{row.name}</td>
                      <td>{row.eventDate}</td>
                      <td>
                        {new Date(row.closeDate).toLocaleDateString('cs-CZ')}
                      </td>
                      <td>
                        {row.active
                          ? row.capacity - row.attendees > 0
                            ? row.capacity - row.attendees
                            : 'Kapacita naplněna'
                          : 'Přihlášky uzavřeny'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>Aktuálně nejsou vypsány žádné akce.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <p>
              Přehled táborů se nepodařilo načíst. Zkuste to znovu za chvilku.
              Pokud problém přetrvá, <Link to="/kontakt">kontaktujte nás</Link>.
            </p>
          )}
        </div>
        <h2>Přihláška</h2>
        <p>Prosím, vyberte tábor a vyplňte Vaše údaje a údaje dítěte.</p>
        <p>
          Neobdržíte-li do 10 minut potvrzení o přihlášení, kontaktujte{' '}
          <Link to="/kontakt">předsedu odboru</Link>.<br />
        </p>
        <FormCampsSignup camps={rows} />
      </section>
    </Layout>
  )
}

export default Tabory
